var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pack-offers mt-auto" }, [
    _vm._m(0),
    _c("div", { staticClass: "container-fluid" }, [
      _c(
        "div",
        { staticClass: "pack-offers-items" },
        _vm._l(_vm.products, function(product) {
          return _c(
            "div",
            { key: product.product_id, staticClass: "pack-offers-item-wrap" },
            [
              _c("div", { staticClass: "pack-offers-item" }, [
                _c("div", { staticClass: "pack-offers-item__count" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.infoByPrice[product.product_price].amount) +
                      " отчетов\n          "
                  )
                ]),
                _c("div", { staticClass: "pack-offers-item__price" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm._f("currency")(product.product_price)) +
                      "\n          "
                  )
                ]),
                _c("div", { staticClass: "pack-offers-item__desc" }, [
                  _vm._v(
                    "\n            (" +
                      _vm._s(
                        _vm._f("currency")(
                          _vm.infoByPrice[product.product_price].priceForOne
                        )
                      ) +
                      " за отчет)\n          "
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "pack-offers-item__btn mt-3" },
                  [
                    _c(
                      "app-button",
                      {
                        attrs: { disabled: _vm.orderRequest, size: "lg" },
                        on: {
                          click: function($event) {
                            return _vm.checkout(product.product_id)
                          }
                        }
                      },
                      [_vm._v("\n              Купить\n            ")]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        }),
        0
      )
    ]),
    _vm._m(1)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "pack-offers-head text-center" }, [
        _c("h1", [_vm._v("Часто заказываете отчеты?")]),
        _c("h2", [
          _vm._v("Купите пакет и заказывайте в один клик по выгодной цене")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "pack-offers-footer text-center mt-5" }, [
        _c("h6", [
          _vm._v(
            "При покупке оптом все виды отчетов оформляются по одной цене."
          )
        ]),
        _c("h6", [
          _vm._v(
            "\n        Необходимо больше 400 отчетов? Все подробности по email\n        "
          ),
          _c("a", { attrs: { href: "mailto:help@realtycloud.ru" } }, [
            _vm._v("help@realtycloud.ru")
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }