import Vue from 'vue';
import Component from 'vue-class-component';
import filter from 'lodash/filter';

import AppShortRegistration from '@/components/layout/Dialogs/ShortRegistration.vue';

@Component
export default class PackageOffers extends Vue {
  // props

  // data()
  infoByPrice: ByKey<{ amount: number; priceForOne: number }> = {
    999: {
      amount: 5,
      priceForOne: 199,
    },
    1499: {
      amount: 10,
      priceForOne: 149,
    },
    2499: {
      amount: 25,
      priceForOne: 99,
    },
    4999: {
      amount: 100,
      priceForOne: 49,
    },
    9999: {
      amount: 400,
      priceForOne: 25,
    },
  };

  // computed
  get products(): Product[] {
    const products: Product[] = filter<Product>(this.$store.state.products, {
      product_type: 'balance',
    });

    if (products && products.length) {
      return products.filter((product) => {
        return product.product_name !== 'AccountBalanceReplenishmentCustom';
      });
    }

    return [];
  }

  get orderRequest(): boolean {
    return this.$store.state.order.loading;
  }

  get balance(): number {
    return this.$store.state.auth.balance;
  }

  // lifecycle hooks
  beforeDestroy() {
    this.$store.commit('order/changeItem', []);
    this.$store.commit('order/clearData');
  }

  // methods
  checkout(productId: string) {
    if (!this.$store.state.auth.user) {
      return this.$dialog.open({
        component: AppShortRegistration,
        props: {
          onRegistration: () => this.checkout(productId),
        },
      });
    }

    this.$store.commit('order/setObjectKey', 'package_offers');
    this.$store.commit('order/changeItem', [ productId ]);
    this.$store.commit('order/setData', {
      [productId]: {
        comment: 'need_to_add_coupons',
      },
    });
    this.$store.dispatch('order/send', {
      redirect_url: 'https://egrn.realtycloud.ru/package',
    } as OrderOptions);
  }
}
